import { memo, useCallback, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";
import PageLayout from "../../../../components/PageLayout";
import { DocumentReviewDialog } from "../../components/DocumentReviewDialog";
import { useDocumentListWithData } from "../../hooks/useDocumentListWithData";
import { PageVerificationHeader } from "../../components/PageVerificaionLayout";
import { PageVerificationContent } from "../../components/PageVerificationContent";
import { ApplicantSubtitle } from "../../components/ApplicantSubtitle";
import { getTitle } from "../../utils/getTitle";
import { DocumentRequestInfoDialog } from "../../components/DocumentRequestInfoDialog";
import { useVerificationRequestStatistic } from "../../hooks/useVerificationRequestStatistic";
import { GenerateLinkButton } from "../../components/GenerateLinkButton";
import { ApplicantSummaryButton } from "../../components/ApplicantSummaryButton";
import { CardTitleGrid } from "./styles";
import { useWidth } from "../../../app/hooks/useWidth";
import { SendToVerificationDialog } from "../../components/SendToVerificationDialog";
import { compareIsNamesEqual } from "./compareNames";

enum IdentificationDocTypeView {
  "Id" = "ID",
  "Passport" = "Passport",
}

export const VerificationRequestScreen = memo(() => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [isRequestInfoOpen, setIsRequestInfoOpen] = useState(false);
  const [isSendToVerificationOpen, setIsSendToVerificationOpen] =
    useState(false);
  const [value, setValue] = useState<"accept" | "decline" | null>(null);
  const [identificationDocumentType, setIdentificationDocumentType] = useState<
    "Passport" | "ID" | null
  >(null);
  const [documents, documentsLoading, documentsError, data, request] =
    useDocumentListWithData(id!);
  const [statistic] = useVerificationRequestStatistic(id!);
  const width = useWidth();
  const location = useLocation();
  const prevPage = useMemo(() => location.search.split("=")[1], [location]);

  const isNamesEqual = useMemo(() => {
    if (data?.externalStatus === "valid") {
      const userInfoData = documents?.find(
        (doc) => doc.document.documentType === "UserInformation"
      )?.data[0]?.data;
      const identityData = documents
        ?.filter(
          (doc) =>
            doc.document.documentType === "Passport" ||
            doc.document.documentType === "Id"
        )
        ?.map((docData) => {
          return docData.data.find((doc) => doc.type === "binary")!;
        });
      if (!identityData) {
        return false;
      }
      if (identityData?.length === 1) {
        setIdentificationDocumentType(
          IdentificationDocTypeView[identityData[0].dataId as "Id" | "Passport"]
        );
        return (
          compareIsNamesEqual(
            userInfoData?.first_name,
            identityData[0]?.data?.first_name
          ) &&
          compareIsNamesEqual(
            userInfoData?.last_name,
            identityData[0]?.data?.last_name
          )
        );
      } else {
        let equal = true;
        for (let indentity of identityData) {
          equal =
            compareIsNamesEqual(
              userInfoData?.first_name,
              indentity?.data?.first_name
            ) &&
            compareIsNamesEqual(
              userInfoData?.last_name,
              indentity?.data?.last_name
            );
          if (!equal) {
            setIdentificationDocumentType(
              IdentificationDocTypeView[indentity.dataId as "Id" | "Passport"]
            );
            break;
          }
        }
        return equal;
      }
    } else {
      return false;
    }
  }, [documents, data?.externalStatus]);

  const handleOpenReview = useCallback(
    (value) => {
      if (value === "accept" || value === "decline") {
        setIsReviewOpen(true);
        setValue(value);
      } else if (value === "requestInfo") {
        setIsRequestInfoOpen(true);
      } else if (value === "sendToVerify") {
        setIsSendToVerificationOpen(true);
      }
    },
    [setIsReviewOpen]
  );

  const handleCloseReview = useCallback(
    (reason) => {
      setIsReviewOpen(false);
      setIsRequestInfoOpen(false);
      if (
        reason === "accept" ||
        reason === "decline" ||
        reason === "requestInfo"
      ) {
        if (window.history.state && window.history.state.idx > 0) {
          navigate(-1);
        } else {
          navigate("/verification-requests");
        }
      }
    },
    [setIsReviewOpen, navigate]
  );

  const displayedDocuments = useMemo(() => {
    return documents?.map((doc) => {
      const docType = doc.document.documentType;
      if (docType !== "UserInformation") return doc;
      const documentCopy = JSON.parse(JSON.stringify(doc));
      if (!doc.data[0].data?.email) {
        documentCopy.data[0].data.email = data?.email;
      }
      return documentCopy;
    });
  }, [data, documents]);

  let errorMessage: string | null = null;
  if (documentsError) {
    errorMessage = documentsError.toString();
  }
  return (
    <PageLayout
      title={getTitle(id!, data)}
      subTitle={
        <ApplicantSubtitle
          deletedAt={data?.deletedAt}
          status={data?.deletedAt ? "DELETED" : data?.status}
        />
      }
      backUrl={`/verification-requests/page/${prevPage}#${id}`}
      onlyUrl
      actions={
        !["xs", "sm"].includes(width) ? (
          <CardTitleGrid>
            <ApplicantSummaryButton
              documents={displayedDocuments ?? documents}
              data={data}
              id={id}
            />
            <GenerateLinkButton
              id={data?.id}
              appPublicKey={data?.appPublicKey}
            />
          </CardTitleGrid>
        ) : null
      }
      loading={documentsLoading}
      empty={documentsError === null && data === null}
    >
      {errorMessage !== null && <Alert severity='error'>{errorMessage}</Alert>}
      {["xs", "sm"].includes(width) ? (
        <CardTitleGrid>
          <ApplicantSummaryButton
            documents={displayedDocuments ?? documents}
            data={data}
            id={id}
          />
          <GenerateLinkButton id={data?.id} appPublicKey={data?.appPublicKey} />
        </CardTitleGrid>
      ) : null}

      <PageVerificationHeader data={data} />
      <PageVerificationContent
        loading={documentsLoading}
        request={request}
        documents={displayedDocuments ?? documents}
        data={data}
        statistic={statistic}
        id={id}
        onAction={handleOpenReview}
      />
      <DocumentReviewDialog
        identificationDocumentType={identificationDocumentType}
        isNamesEqual={isNamesEqual}
        status={data?.externalStatus}
        documentId={id!}
        open={isReviewOpen}
        onClose={handleCloseReview}
        value={value}
      />
      <DocumentRequestInfoDialog
        documentId={id!}
        open={isRequestInfoOpen}
        onClose={handleCloseReview}
        data={data}
      />
      <SendToVerificationDialog
        verificationId={id!}
        request={request}
        open={isSendToVerificationOpen}
        onClose={() => setIsSendToVerificationOpen(false)}
      />
    </PageLayout>
  );
});
