import { memo, useCallback, useEffect, useRef, useState } from "react";
import { Alert, Box, Pagination, Stack, Typography } from "@mui/material";
import PageLayout from "../../../../components/PageLayout";
import { useDocumentsList } from "../../hooks/useVerificationRequestList";
import { DataGrid, FunctionViewField, ListContainer, TId } from "../../../ui";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TVerificationRequest } from "../../models";
import { getTitle } from "../../utils/getTitle";
import { getDocumentIcon } from "../../utils/getDocumentIcon";
import { statusesModel, getStatusIcon } from "../../utils/getStatus";
import { HeadButtons } from "../../../../components/HeadButtons";
import { SearchInput } from "../../../../components/SearchInput";
import { useAuth } from "../../../../auth/useAuth";
import {
  AUTH_COMPANY_NAME,
  AUTH_COMPANY_PURPOSE,
} from "../../../company/models";
import { getLocaleStringdDate } from "../../../../utils/dateFormatt";
import { sortByDate } from "../../utils/sortByDate";

export const VerificationRequestListScreen = memo(() => {
  const [offset, setOffset] = useState(0);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [query, setQuery] = useState<string>("");
  const { authorizedCompanyId, companies } = useAuth();
  const currentCompany = localStorage.getItem(AUTH_COMPANY_NAME);
  const navigate = useNavigate();
  const { pageNum } = useParams<{ pageNum: string }>();
  const limitOnPage = 50;
  const [data, loading, error, request] = useDocumentsList(
    offset,
    limitOnPage,
    authorizedCompanyId,
    statuses,
    query
  );
  const linkRef = useRef<any>(null);
  const location = useLocation();

  const onRowClick = useCallback(
    (id: TId) => {
      navigate(`/verification-requests/${id}?page=${pageNum}`);
    },
    [navigate, pageNum]
  );

  const refresh = useCallback(
    (offset, statuses, query) => {
      if (pageNum && +pageNum === 1) {
        request(offset, statuses, query);
      } else {
        navigate(`/verification-requests/page/${1}`, { replace: true });
      }
    },
    [request, pageNum, navigate]
  );

  useEffect(() => {
    if (!pageNum) {
      navigate(`/verification-requests/page/${1}`, { replace: true });
    } else {
      const test = async () => {
        const newOffset = limitOnPage * (+pageNum - 1);
        setOffset(newOffset);
        await request(newOffset, statuses, query);
        if (location.hash) {
          linkRef.current?.click();
          navigate(location.pathname, { replace: true });
        }
      };
      test();
    }
  }, [pageNum, query, request, statuses, navigate]);

  useEffect(() => {
    const selectedCompanyPurpose = companies?.items?.find(
      (comapany: any) => comapany.name === currentCompany
    )?.purpose;
    const purpose = localStorage.getItem(AUTH_COMPANY_PURPOSE);
    if (selectedCompanyPurpose && selectedCompanyPurpose !== purpose) {
      localStorage.setItem(AUTH_COMPANY_PURPOSE, selectedCompanyPurpose);
    }
  }, [companies, currentCompany]);

  return (
    <PageLayout
      title={`Verification Requests for ${currentCompany}`}
      loading={loading}
      empty={error === null && (data === null || data.items.length === 0)}
    >
      {location.hash ? (
        <a style={{ display: "none" }} href={location.hash} ref={linkRef}>
          invisible
        </a>
      ) : null}

      <Stack direction='row' spacing={2} justifyContent='end'>
        <HeadButtons
          onChangeFilter={setStatuses}
          onReload={() => refresh(offset, statuses, query)}
        />
        <SearchInput onChangeValue={setQuery} />
      </Stack>
      {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      <Pagination
        count={Math.floor(data?.count / limitOnPage) + 1}
        page={Math.floor(offset / limitOnPage) + 1}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          navigate(`/verification-requests/page/${value}`, { replace: true });
        }}
        sx={{ mb: "16px" }}
      />
      <ListContainer
        // data={sortByDate(data?.items, "descending", "updatedAt")}
        data={data?.items}
        isLoading={loading}
      >
        <DataGrid onRowClick={onRowClick}>
          <FunctionViewField
            label='Name'
            source='name'
            render={(record: TVerificationRequest) => (
              <Box sx={{ scrollMarginTop: "180px" }} id={record.id}>
                <Typography variant='h6'>
                  {getTitle(record.id, record)}
                </Typography>
                <Typography variant='body2'>ID: {record.id}</Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Documents'
            render={(record: TVerificationRequest) => (
              <Box>
                <Stack
                  flexWrap='wrap'
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='center'
                  spacing={1}
                >
                  {record.documents &&
                    record.documents.map((x) =>
                      getDocumentIcon(x.document.documentType, {
                        sx: { color: "#D2B102" },
                      })
                    )}
                  {/* <DocumentIcon
                    sx={{
                      color:
                        record.status === 'Approved'
                          ? '#2CC369'
                          : record.status === 'Cancelled'
                          ? '#CF0000'
                          : '#D2B102',
                    }}
                  />
                  {record.status.statusDoc !== 'Cancelled' && (
                    <LocationIcon
                      sx={{
                        color:
                          record.status === 'Approved'
                            ? '#2CC369'
                            : record.status === 'Cancelled'
                            ? '#CF0000'
                            : '#D2B102',
                      }}
                    />
                  )}
                  <CredentialIcon
                    sx={{
                      color:
                        record.status === 'Approved'
                          ? '#2CC369'
                          : record.status === 'Cancelled'
                          ? '#CF0000'
                          : '#D2B102',
                    }}
                  /> */}
                </Stack>
              </Box>
            )}
          />

          <FunctionViewField
            label='Status'
            source='status'
            render={(record: any) => (
              <Stack
                direction='row'
                justifyContent='flex-start'
                alignItems='center'
                spacing={1}
                minWidth={"200px"}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    background:
                      statusesModel[
                        record.deletedAt ? "DELETED" : record.status
                      ]?.bgColor,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    position='relative'
                  >
                    <Box position='absolute' top='0' left='0'>
                      {getStatusIcon(
                        record.deletedAt ? "DELETED" : record.status
                      )}
                    </Box>
                    <Typography
                      variant='body2'
                      sx={{
                        paddingLeft: "25px",
                        fontWeight: "700",
                        color:
                          statusesModel[
                            record.deletedAt ? "DELETED" : record.status
                          ]?.textColor,
                      }}
                    >
                      {
                        statusesModel[
                          record.deletedAt ? "DELETED" : record.status
                        ]?.name
                      }
                    </Typography>
                  </Stack>
                </Box>
              </Stack>
            )}
          />
          <FunctionViewField
            label='Created at'
            source='createdAt'
            render={(record: TVerificationRequest) => (
              <Box>
                <Typography variant='body2'>
                  {record?.createdAt
                    ? getLocaleStringdDate(
                        new Date(record?.createdAt).toString()
                      )
                    : "-"}
                </Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Updated at'
            source='updatedAt'
            render={(record: TVerificationRequest) => (
              <Box>
                <Typography variant='body2'>
                  {record?.updatedAt
                    ? getLocaleStringdDate(
                        new Date(record?.updatedAt).toString()
                      )
                    : "-"}
                </Typography>
              </Box>
            )}
          />
          <FunctionViewField
            label='Decision date'
            source='decisionTime'
            render={(record: TVerificationRequest) => (
              <Box>
                <Typography variant='body2'>
                  {record?.decisionTime
                    ? getLocaleStringdDate(
                        new Date(record?.decisionTime).toString()
                      )
                    : "-"}
                </Typography>
              </Box>
            )}
          />
        </DataGrid>
        <Pagination
          count={Math.floor(data?.count / limitOnPage) + 1}
          page={Math.floor(offset / limitOnPage) + 1}
          onChange={(event: React.ChangeEvent<unknown>, value: number) => {
            navigate(`/verification-requests/page/${value}`, { replace: true });
          }}
          sx={{ mb: "16px" }}
        />
      </ListContainer>
    </PageLayout>
  );
});
