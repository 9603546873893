import { FC, useCallback, useState } from "react";
import { HeadButton } from "../../../../components/HeadButtons/styles";
import { DocumentIcon } from "../../../../components/MuiIcons";
import jsPDF from "jspdf";
import { TResult } from "../../hooks/useDocumentListWithData";
import { titleCase } from "../../../../utils";
import scheme from "../DocumentInfoCard/scheme.json";
import { getTitle } from "../../utils/getTitle";
import { statusesModel } from "../../utils/getStatus";
import { externalStatusModel } from "../DatabaseScreeningPanel";
import { getFormattedDate } from "../../../../utils/dateFormatt";
import onboardLogo from "./oneboardLogo.png";
import countries from "../../data/countries.json";
import { AUTH_COMPANY_PURPOSE } from "../../../company/models";
import { ptSans } from "./encodeSans";
import { PDFDocument } from "pdf-lib";

// export const FilesType: { [key: string]: object } = {
//   "application/pdf": {
//     description: "PDF",
//     accept: {
//       "application/pdf": ".pdf",
//     },
//   },
//   "text/plain": {
//     description: "Text",
//     accept: {
//       "text/plain": ".txt",
//     },
//   },
//   "image/jpeg": {
//     description: "Image",
//     accept: {
//       "image/jpeg": ".jpeg",
//     },
//   },
//   "image/png": {
//     description: "Image",
//     accept: {
//       "image/jpeg": ".png",
//     },
//   },
//   "image/svg+xml": {
//     description: "Image",
//     accept: {
//       "image/jpeg": ".svg",
//     },
//   },
// };

const PAGE_MARGIN = 20;
const FULLPAGE_WIDTH = 595;
const PAGE_WIDTH = FULLPAGE_WIDTH - PAGE_MARGIN * 2;
const PAGE_HEIGHT = 842 - PAGE_MARGIN * 2;

const MAX_IMAGE_HEIGHT = PAGE_HEIGHT / 4;
const MAX_IMAGE_WIDTH = PAGE_WIDTH / 2;

const FOOTNOTE_HEIGHT = 50;
// let buffer: ArrayBuffer | null = null;
const ITEMS_TO_SKIP = [
  "identifier",
  "issued_by",
  "issued_at",
  "expired_at",
  "decline_reason",
];

let wasFootnote = false;

// const saveFile = async (blob: Blob, suggestedName: string) => {
//   const supportsFileSystemAccess = null;
//   if (supportsFileSystemAccess) {
//     try {
//       //@ts-ignore
//       const handle = await window.showSaveFilePicker(options);
//       const writable = await handle.createWritable();
//       await writable.write(blob);
//       await writable.close();
//       return;
//     } catch (err: any) {
//       if (err.name !== "AbortError") {
//         console.error(err.name, err.message);
//         return;
//       }
//     }
//   } else {
//     const blobURL = URL.createObjectURL(blob);
//     const a = document.createElement("a");
//     a.href = blobURL;
//     a.download = suggestedName;
//     a.style.display = "none";
//     document.body.append(a);
//     a.click();
//     setTimeout(() => {
//       URL.revokeObjectURL(blobURL);
//       a.remove();
//     }, 1000);
//   }
// };

// const insertPdf = async (pdf: jsPDF, insertingPdfBlob: ArrayBuffer) => {
//   const currentPdfBuffer = pdf.output("arraybuffer");
//   const pdfDoc = await PDFDocument.create();

//   const currentDoc = await PDFDocument.load(currentPdfBuffer);
//   const insertDoc = await PDFDocument.load(insertingPdfBlob);

//   const pages1 = await pdfDoc.copyPages(
//     currentDoc,
//     currentDoc.getPageIndices()
//   );
//   const pages2 = await pdfDoc.copyPages(insertDoc, insertDoc.getPageIndices());
//   pages1.forEach((page) => pdfDoc.addPage(page));
//   pages2.forEach((page) => pdfDoc.addPage(page));
//   const mergedPdfBytes = await pdfDoc.save();
//   const blob = new Blob([mergedPdfBytes], { type: "application/pdf" });
//   // let jsPdfDoc = new jsPDF();
//   // jsPdfDoc.loadFile(blob);
//   saveFile(blob, "test");
// };

const addPage = (pdf: jsPDF, margin: number = PAGE_MARGIN * 2) => {
  wasFootnote = false;
  pdf.addPage();
  addLogoToTop(pdf);
  let startY = margin; // Restart height position
  startY += 30;
  return startY;
};

const addImageInPdf = (
  pdf: jsPDF,
  link: string,
  startY: number,
  dataId: string,
  option: { leftMargin?: number; topMargin?: number }
) => {
  const leftMargin = option?.leftMargin ?? PAGE_MARGIN * 2;
  const topMargin = option?.topMargin ?? 10;

  const wrtiteType = (startY: number, type: string | null) => {
    startY = writelineInPdf(pdf, `type: ${type}`, startY, {
      fontSize: 8,
      lineHeight: 14,
      leftMargin: leftMargin,
    });
    return startY;
  };

  return new Promise<number>(async function (resolve, reject) {
    if (!link) resolve(startY);

    const response = await fetch(link);
    const contentType = response.headers.get("content-type");

    if (!contentType?.includes("image")) {
      // const testDoc = new jsPDF();
      // testDoc.loadFile(link);
      // testDoc.save();
      // console.log("testDoc", testDoc);

      // buffer = await response.arrayBuffer();
      startY = wrtiteType(startY, contentType);
      startY += 20;
      pdf.setTextColor("blue");
      pdf.textWithLink("Please download it here", leftMargin, startY, {
        url: link,
      });
      pdf.setTextColor("#000000");
      resolve(startY);
    }

    const promise = new Promise((resolve) => {
      const image = new Image();
      let imageToShow = null;
      let width, height: number;
      image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
      image.src = link;
      image.onload = function () {
        var canvas = document.createElement("canvas");
        //@ts-ignore
        canvas.width = this.naturalWidth;
        //@ts-ignore
        canvas.height = this.naturalHeight;
        //@ts-ignore
        [width, height] = [this.naturalWidth, this.naturalHeight];
        //@ts-ignore
        canvas.getContext("2d").drawImage(this, 0, 0);
        imageToShow = canvas.toDataURL("image/jpeg");
        resolve({ imageToShow, width, height });
      };
    });
    promise.then((image: any) => {
      let imageHeight = image.height;
      let imageWidth = image.width;
      if (imageHeight > MAX_IMAGE_HEIGHT) {
        let k = MAX_IMAGE_WIDTH / imageHeight;
        imageHeight *= k;
        imageWidth *= k;
      }
      if (imageWidth > MAX_IMAGE_WIDTH) {
        let k = MAX_IMAGE_WIDTH / imageWidth;
        imageHeight *= k;
        imageWidth *= k;
      }

      startY += imageHeight + topMargin;
      if (
        startY >= (!wasFootnote ? PAGE_HEIGHT : PAGE_HEIGHT - FOOTNOTE_HEIGHT)
      ) {
        startY = addPage(pdf, PAGE_MARGIN * 2 + imageHeight);
      }
      pdf.addImage(
        image.imageToShow,
        "PNG",
        leftMargin,
        startY - imageHeight,
        imageWidth,
        imageHeight
      );

      startY = wrtiteType(startY, contentType);
      resolve(startY);
    });
  });
};

const writelineInPdf = (
  pdf: jsPDF,
  initialText: string,
  startY: number,
  option?: {
    fontSize?: number;
    color?: string;
    topMargin?: number;
    leftMargin?: number;
    lineHeight?: number;
    fontStyle?: "italic" | "normal" | "bold";
    isLineBreak?: boolean;
  }
) => {
  const fontSize = option?.fontSize ?? 10;
  const color = option?.color ?? "#000000";
  const topMargin = option?.topMargin ?? 0;
  const leftMargin = option?.leftMargin ?? PAGE_MARGIN * 2;
  const lineHeight = option?.lineHeight ?? fontSize * 1.66;
  const fontStyle = option?.fontStyle ?? "normal";
  const isLineBreak = option?.isLineBreak ?? true;

  pdf.setFontSize(fontSize);
  pdf.setTextColor(color);
  pdf.addFileToVFS("ptsans.ttf", ptSans[fontStyle]);
  pdf.addFont("ptsans.ttf", "ptsans", fontStyle);
  pdf.setFont("ptsans", fontStyle);

  let text = pdf.splitTextToSize(
    initialText,
    FULLPAGE_WIDTH - leftMargin - PAGE_MARGIN * 2
  );
  if (
    text[0].includes("Source of funds") ||
    text[0].includes("Source of wealth")
  ) {
    text[0] += " EUR per year";
  }
  if (text[0].includes("Currency:")) {
    return startY;
  }
  for (let i = 0; i < text.length; i++) {
    if (isLineBreak) startY += topMargin + lineHeight;

    if (
      startY >= (!wasFootnote ? PAGE_HEIGHT : PAGE_HEIGHT - FOOTNOTE_HEIGHT)
    ) {
      startY = addPage(pdf);
    }
    pdf.text(text[i], leftMargin, startY);
  }

  return startY;
};

const addLogoToTop = (pdf: jsPDF) => {
  pdf.setFillColor("#ebeaea");
  var img = new Image();
  img.src = onboardLogo;
  pdf.rect(0, 0, 595, 50, "F");
  pdf.addImage(img, "png", 282, 10, 60, 30);
  pdf.setFillColor("#ffffff");
  pdf.rect(20, 50, 595, 20, "F");
  pdf.circle(20, 70, 20, "F");
  pdf.circle(595 - 20, 70, 20, "F");
};

interface IFootNote {
  pdf: jsPDF;
  withLine?: boolean;
  fontSize?: number;
  leftMargin?: number;
  text?: string;
}

const addFootnote = ({
  pdf,
  withLine = true,
  fontSize = 8,
  leftMargin = PAGE_MARGIN * 2,
  text = "* The user profiles are screened against global databases comprising politically exposed persons, sanctions lists, watchlists and adverse media. This includes all major international and national lists published by governments and independent, international and non-governmental organizations (OFAC, UN, HMT, EU, DFAT and many more lists from around the world).",
}: IFootNote) => {
  let y = 760;
  if (withLine) {
    pdf.line(PAGE_MARGIN * 2, y, PAGE_WIDTH, y);
  }

  y = writelineInPdf(pdf, text, y + 8, {
    fontSize: fontSize,
    leftMargin: leftMargin,
    lineHeight: 10,
    topMargin: 0,
  });
  wasFootnote = true;
};

export const ApplicantSummaryButton: FC<{
  documents: TResult;
  data: any;
  id: string | undefined;
}> = ({ documents, data, id }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generatePdf = useCallback(async () => {
    setIsLoading(true);
    const pdf = new jsPDF("p", "pt", "a4");
    let startY = PAGE_MARGIN;

    addLogoToTop(pdf);
    startY += 30;

    startY = writelineInPdf(pdf, "BUSINESS PROFILE", startY, {
      fontSize: 16,
      leftMargin: 230,
      fontStyle: "bold",
    });

    startY = writelineInPdf(pdf, getTitle(id!, data), startY, {
      fontSize: 16,
    });
    let date = new Date().toLocaleDateString();
    startY = writelineInPdf(pdf, `Report for ${date}`, startY, {
      fontStyle: "italic",
    });
    startY = writelineInPdf(pdf, `Verification ID ${id}`, startY, {
      leftMargin: 155,
      fontStyle: "italic",
      isLineBreak: false,
    });
    startY = writelineInPdf(pdf, "Verification Status:", startY, {});
    startY = writelineInPdf(pdf, statusesModel[data?.status]?.name, startY, {
      color: statusesModel[data?.status]?.textColor,
      isLineBreak: false,
      leftMargin: 155,
    });

    startY = writelineInPdf(pdf, "Database screening *", startY, {
      fontSize: 16,
      lineHeight: 24,
      // topMargin: 10,
    });
    startY = writelineInPdf(pdf, "Current status:", startY, {
      leftMargin: PAGE_MARGIN * 3,
    });
    startY = writelineInPdf(pdf, data.externalStatus, startY, {
      leftMargin: 155,
      isLineBreak: false,
      color:
        externalStatusModel[
          data.externalStatus as keyof typeof externalStatusModel
        ]?.color,
    });
    startY = writelineInPdf(
      pdf,
      `Ongoing monitoring (daily screening): ${
        data.externalStatus === "valid" ? "ON" : "-"
      }`,
      startY,
      {
        leftMargin: PAGE_MARGIN * 3,
      }
    );
    if (data.declineReason?.length > 0) {
      startY = writelineInPdf(pdf, `Decline reasons:`, startY, {
        leftMargin: PAGE_MARGIN * 3,
      });
      data.declineReason.forEach((reason: string, index: number) => {
        startY = writelineInPdf(pdf, `${reason.replace(/_/g, " ")}`, startY, {
          leftMargin: 155,
          isLineBreak: index !== 0,
        });
      });
    }
    pdf.line(PAGE_MARGIN * 2, startY + 15, PAGE_WIDTH, startY + 15);

    addFootnote({ pdf });

    startY = writelineInPdf(pdf, "Questionnaire", startY, {
      fontSize: 16,
      topMargin: 15,
    });

    for (const document of documents!!) {
      const data = document.data;
      const documentTitle = document.document.documentType;
      const binary: typeof data = [];
      const files: typeof data = [];
      data.forEach((x) => {
        if (x.type === "binary") {
          binary.push(x);
        } else if (x.type === "file") {
          files.push(x);
        }
      });

      startY = writelineInPdf(pdf, `${titleCase(documentTitle)}:`, startY, {
        fontSize: 12,
        lineHeight: 16,
      });

      for (const binaryElement of binary) {
        const itemScheme =
          (scheme.dataTypes[
            binaryElement.dataId as keyof typeof scheme.dataTypes
          ] as any) || null;

        if (!itemScheme) continue;
        for (const schemeField of itemScheme?.fields) {
          let schemeFieldName =
            typeof schemeField === "string"
              ? titleCase(schemeField)
              : schemeField?.[1]?.label || titleCase(schemeField?.[0]);
          let schemeFieldKey =
            typeof schemeField === "string" ? schemeField : schemeField?.[0];

          let value: any = binaryElement?.data?.[schemeFieldKey];

          if (ITEMS_TO_SKIP.includes(schemeFieldKey)) {
            continue;
          }
          if (schemeFieldKey === "middle_name") {
            let country = countries.find(
              (country) => country.code === value
            )?.name;
            if (country || !value) {
              value = country;
              schemeFieldName = "Second Nationality";
            }
          }

          if (value && schemeField?.[1]?.type === "date") {
            value = getFormattedDate(new Date(value));
          }

          startY = writelineInPdf(
            pdf,
            `${schemeFieldName}:  ${value || "—"}`,
            startY,
            {
              leftMargin: PAGE_MARGIN * 3,
            }
          );
        }
      }

      if (files.length > 0) {
        startY = writelineInPdf(pdf, "Files:", startY, {
          fontSize: 10,
          leftMargin: PAGE_MARGIN * 2.5,
          fontStyle: "italic",
        });
      }

      for (const file of files) {
        startY = writelineInPdf(pdf, `· ${file.dataId}`, startY, {
          leftMargin: PAGE_MARGIN * 3,
        });
        if (file) {
          startY = await addImageInPdf(
            pdf,
            file.dataLink,
            startY,
            file.dataId,
            {
              leftMargin: PAGE_MARGIN * 3 + 8,
            }
          );
        }
      }
      startY = writelineInPdf(pdf, "Comment:", startY, {
        leftMargin: PAGE_MARGIN * 3,
      });
      if (documentTitle === "BeneficialOwnership") {
        const purpose = localStorage.getItem(AUTH_COMPANY_PURPOSE);
        startY = writelineInPdf(pdf, `Use of Funds:`, startY, {
          fontSize: 12,
          lineHeight: 16,
        });
        startY = writelineInPdf(
          pdf,
          `Purpose of establishing the business relationship:  ${
            purpose || "—"
          }`,
          startY,
          {
            leftMargin: PAGE_MARGIN * 3,
          }
        );
      }
    }

    addFootnote({
      pdf,
      withLine: false,
      text: "Signed by Compliance Officer: ________________________",
      leftMargin: PAGE_MARGIN * 2,
      fontSize: 12,
    });
    // insertPdf(pdf, buffer!);
    pdf.save(`Business-Profile-${id}.pdf`);
    setIsLoading(false);
  }, [data, documents, id]);

  return (
    <>
      <HeadButton
        variant='text'
        onClick={generatePdf}
        startIcon={<DocumentIcon />}
        disabled={isLoading}
        sx={{
          "& .MuiButton-startIcon": {
            marginRight: "4px",
            position: "relative",
            top: "2px",
          },
        }}
      >
        Applicant Business Profile
      </HeadButton>
    </>
  );
};
