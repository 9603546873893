import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { Alert, FormControlLabel, Grid, Switch } from "@mui/material";
import { useCompanyAction } from "../../hooks/useCompanyAction";
import { FileInput } from "../../../ui/components/FileInput";
import { getS3UploadUrl, TCreateCompanyData, uploadToS3 } from "../../network";
import { isEmpty } from "lodash";
import {
  AUTH_COMPANY_NAME,
  ICompanyActionModeType,
  TCompany,
} from "../../models";

export type Props = {
  onClose: (success: boolean) => void;
  open: boolean;
  actionMode?: ICompanyActionModeType;
  selectedCompany?: TCompany | null;
};

type FormValues = {
  name: string;
  purpose: string;
  picture?: File;
};

export const CreateCompanyDialog = (props: Props) => {
  const { onClose, open, actionMode = "create", selectedCompany } = props;
  const [result, loading, error, request] = useCompanyAction();
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  const [purpose, setPurpose] = useState("");
  const [picture, setPicture] = useState<File | string | null>(null);
  const [errors, setErrors] = useState<any>({});
  const currentCompany = localStorage.getItem(AUTH_COMPANY_NAME);

  const handleCancel = useCallback(
    (success: boolean = false) => {
      setName("");
      setPurpose("");
      setIsActive(true);
      setPicture(null);
      setErrors({});
      onClose(success);
    },
    [onClose]
  );

  useEffect(() => {
    if (result) {
      handleCancel(true);
    }
  }, [result, handleCancel]);

  useEffect(() => {
    if (selectedCompany) {
      setIsActive(selectedCompany.isActive);
      setName(selectedCompany.name);
      setPurpose(selectedCompany.purpose);
      setPicture(selectedCompany.picture);
    }
  }, [selectedCompany]);

  const validate = useCallback((formData: FormValues) => {
    const errors: any = {};

    if (!formData.name) {
      errors.name = true;
    }
    if (!formData.purpose) {
      errors.purpose = true;
    }
    setErrors(errors);
    return errors;
  }, []);

  const onSubmit = useCallback(async () => {
    const formValues: FormValues = {
      name,
      purpose,
    };
    const validateErrors = validate(formValues);
    if (isEmpty(validateErrors)) {
      const requestData: TCreateCompanyData = { name, purpose, isActive };
      if (picture) {
        if (typeof picture === "object") {
          try {
            const { key, url } = await getS3UploadUrl(
              picture.type,
              picture.size
            );
            await uploadToS3(url, picture, picture.type);
            requestData.picture = key;
          } catch (e) {
            console.error(e);
          }
        }
      }
      if (actionMode === "edit") {
        requestData.id = selectedCompany!.id;
      }
      if (selectedCompany?.name === currentCompany) {
        localStorage.setItem(AUTH_COMPANY_NAME, name);
      }
      request(requestData, actionMode);
    }
  }, [
    request,
    actionMode,
    validate,
    name,
    picture,
    purpose,
    selectedCompany,
    isActive,
    currentCompany,
  ]);

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth='xs'
      open={open}
      id='create-company-dialog'
      keepMounted
    >
      <DialogTitle sx={{ textTransform: "capitalize" }}>
        {actionMode} company
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column'>
          <FormControlLabel
            control={<Switch />}
            label={isActive ? "Active" : "Inactive"}
            name={"isActive"}
            checked={isActive}
            onChange={() => setIsActive((prev) => !prev)}
          />
          <TextField
            error={errors.name}
            label='Company name'
            name='name'
            required={true}
            size='small'
            margin='normal'
            value={name}
            inputProps={{
              onChange: (e: any) => {
                delete errors.name;
                return setName(e.target.value);
              },
            }}
          />
          <TextField
            error={errors.purpose}
            label='Purpose of relationship for onboarded users'
            name='purpose'
            required={true}
            size='small'
            margin='normal'
            value={purpose}
            inputProps={{
              onChange: (e: any) => {
                delete errors.purpose;
                return setPurpose(e.target.value);
              },
            }}
          />
          <FileInput
            label='Logo'
            namePrefix='picture'
            handleChange={setPicture}
            value={picture}
          />
        </Grid>
        {error !== null && <Alert severity='error'>{error.toString()}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleCancel()} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={onSubmit} disabled={loading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
